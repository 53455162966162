<footer   [style.backgroundImage]="'url(' + serverPath1+footerBrandImg + ')'"  class="footer-v1" style="max-width: 100%;">
  <div class="theme-container">
    <div fxLayout="row" fxLayout.lt-md="column" 
    fxLayoutAlign="space-around start" class="py-3 border-bottom-mute">
      <div fxFlex="10"></div>
      <div fxFlex="15">
        <h3 class="col-title">Discover</h3>
  
        <p class="mt-2"><a routerLink="/aboutus" >About Us</a></p>
        <p *ngIf="data.showContactPage==1" class="mt-1"><a routerLink="/contact-us" >Contact
            Us</a></p>
        <p *ngIf="data.showBlogPage==1" class="mt-1"><a routerLink="/blog" >Blog</a></p>
        <p *ngIf="data.disableCoupoun==1" class="mt-1"><a routerLink="/coupon" >Coupon</a></p>
        <p *ngIf="data.showBrochurePage==1" class="mt-1"><a routerLink="/brochure"
            >Brochure</a></p>
            <p style="cursor: pointer;" class="mt-1" *ngIf="data.enableCustomOption==1"><a (click)="gotoLink(data.customOptionNavigationLink)">{{data.customOptionFooter}}</a></p>
  
      </div>
      <div  fxFlex="15" ngClass.xs="mt-2">
        <h3 class="col-title">Policy</h3>
  
        <p *ngIf="data.disableRefundPolicy==0"  class="mt-2"><a routerLink="/returnPolicy" >{{data.refundPolicyName}}</a></p>
        <p class="mt-1"><a routerLink="/terms&conditions" >Terms & Conditions</a></p>
        <p class="mt-1"><a routerLink="/privacy" >Privacy Policy</a></p>
        <p *ngIf="data.disableShippingPolicy==0" class="mt-1"><a routerLink="/shippingPolicy" >Shipping Policy</a></p>
        <p *ngIf="data.disablePaymentPolicy==0" class="mt-1"><a routerLink="/paymentPolicy" >Payment Policy</a></p>
      </div>
  
      <div fxFlex="25" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information">
        <h3 class="col-title">CONTACT INFORMATION</h3>
  
        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
          <a (click)="callPhone()" style="cursor: pointer; display: flex; align-items: center;">
            <mat-icon>call</mat-icon>
            <span class="footer-color" style="margin-left: 8px;">{{ data.storePhoneNumber }}</span>
          </a>
        </p>
  
        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
          <mat-icon>mail_outline</mat-icon>
          <span  style="cursor: pointer;" (click)="composeEmail(data.smtpUsername)">&nbsp;&nbsp;{{data.smtpUsername}}</span>
        </p>
        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
          <mat-icon style="padding-right: 12px;">location_on</mat-icon>
          <span *ngIf="data.googleMapEnable==1">
            <span style="cursor: pointer;"  (click)="getDirections(data.googleMapNavigation)">{{data.streetAddress}}</span>
          </span>
          <span *ngIf="data.googleMapEnable==0">{{data.streetAddress}}</span>
        </p>
  
      </div>
      <div fxFlex="20" ngClass.xs="mt-2">
        <div *ngIf="showFooterIcon==1">
          <h3 class="col-title">Social Media</h3>
          <div>
            <span fxFlex="15" *ngFor="let item of footerIconData; let i = index">
      
              <button (click)="navigate(item)" mat-icon-button>
      
                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
              </button>
            </span>
      
          </div><br>
        </div>
           <div>
          <h3 class="col-title">{{data.subscribeText}}</h3>
          <calsoft-subscriber-button></calsoft-subscriber-button>
        </div>
      </div>
      <div fxFlex="10"></div>
  
  
    </div>
    <div class="footer-bottom-v1" style="margin: 1px 0px; ">
      <div fxLayout='row wrap'fxLayout.lt-md="column" class="col-gap" fxLayoutAlign="space-between center">
        <div fxFlex="1">
  
        </div>
        <div fxFlex *ngIf="data.disableVisaCard == 0">
          <img src="assets/images/card.jpeg" width="218" alt="Credit Cards">
        </div>
  
        <div fxFlex *ngIf="data.dspeedupNavigationEnable == 1" style="margin-left: 124px;"  [ngStyle.lt-md]="{ 'margin-left': '0px'}" >
          <a class="underline-on-hover" target="_blank" href="https://dspeedup.com/">Powered by dSPEEDUp</a>
        </div>
  
        <div fxFlex style="margin-left: 431px;" [ngStyle.lt-md]="{ 'margin-left': '0px'}">
          <small>Copyright © {{data.storeName}} {{currentYear}}. All rights reserved.</small>
        </div>
  
  
        <div *ngIf="data.vendorEnabled == 1" class="padding-30" fxLayoutAlign="start center">
          <a class="border padding-10" (click)="Supplierlogin()" href="javascript:void(0)">Become a Supplier</a>
        </div>
  
      </div>
    </div>
    </div>
    <div *ngIf="footerImageEnable==1">
      <div *ngIf="display&&imageLoaded">
        <div style="margin-top:auto;" fxHide.lt-md>
          <img [src]="serverPath+footerLogoSrc" (error)="onImageError()"  style="width: 100%;">
        </div>
      </div>
    </div>
    
  </footer>